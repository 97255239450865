exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-bubbleup-bubbleup-tsx": () => import("./../../../src/pages/projects/bubbleup/bubbleup.tsx" /* webpackChunkName: "component---src-pages-projects-bubbleup-bubbleup-tsx" */),
  "component---src-pages-projects-bubbleup-index-tsx": () => import("./../../../src/pages/projects/bubbleup/index.tsx" /* webpackChunkName: "component---src-pages-projects-bubbleup-index-tsx" */),
  "component---src-pages-projects-cat-shelter-cat-shelter-tsx": () => import("./../../../src/pages/projects/cat-shelter/cat-shelter.tsx" /* webpackChunkName: "component---src-pages-projects-cat-shelter-cat-shelter-tsx" */),
  "component---src-pages-projects-cat-shelter-index-tsx": () => import("./../../../src/pages/projects/cat-shelter/index.tsx" /* webpackChunkName: "component---src-pages-projects-cat-shelter-index-tsx" */),
  "component---src-pages-projects-ferret-ferret-tsx": () => import("./../../../src/pages/projects/ferret/ferret.tsx" /* webpackChunkName: "component---src-pages-projects-ferret-ferret-tsx" */),
  "component---src-pages-projects-ferret-index-tsx": () => import("./../../../src/pages/projects/ferret/index.tsx" /* webpackChunkName: "component---src-pages-projects-ferret-index-tsx" */),
  "component---src-pages-projects-led-screen-index-tsx": () => import("./../../../src/pages/projects/led-screen/index.tsx" /* webpackChunkName: "component---src-pages-projects-led-screen-index-tsx" */),
  "component---src-pages-projects-led-screen-led-tsx": () => import("./../../../src/pages/projects/led-screen/led.tsx" /* webpackChunkName: "component---src-pages-projects-led-screen-led-tsx" */),
  "component---src-pages-projects-the-oak-gallery-index-tsx": () => import("./../../../src/pages/projects/the-oak-gallery/index.tsx" /* webpackChunkName: "component---src-pages-projects-the-oak-gallery-index-tsx" */),
  "component---src-pages-projects-the-oak-gallery-the-oak-gallery-tsx": () => import("./../../../src/pages/projects/the-oak-gallery/the-oak-gallery.tsx" /* webpackChunkName: "component---src-pages-projects-the-oak-gallery-the-oak-gallery-tsx" */)
}

